<template>
	<section id="home">
        <video src="/ghc.mp4" muted autoplay loop playsinline></video>
        <h1>Coming<br> Soon</h1>
        <img src="/logo.png" alt="Gourmet House Caviar">
    </section>
</template>

<script>

export default {
	name: 'App',
};

</script>

<style>

@font-face {
    font-family: 'PPMigra-Extralight';
    src: url('~@/assets/fonts/PPMigra-Extralight.woff2') format('woff2'),
        url('~@/assets/fonts/PPMigra-Extralight.woff') format('woff'),
        url('~@/assets/fonts/PPMigra-Extralight.ttf') format('truetype');
}

html, body, #app, #home {
    
    height: 100%;
    margin: 0;

    overflow: hidden;

}

body {
    font-family: 'PPMigra-Extralight', serif;
}

#home {
    position: relative;
}

#home:after {

    content: '';
    
    position: absolute;
    inset: 0;
    
    background-color: rgba(0, 0, 0, 0.3);

}

video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

h1, img {
    position: absolute;
    z-index: 10;
}

h1 {
    
    top: 50%;
    left: 0;

    transform: translateY(-50%);

    width: 100%;
    margin: 0;

    font-size: 100px;
    font-weight: 100;
    text-align: center;
    color: #fff;
    line-height: 1;

}

h1 br {
    display: none;
}

img {
    
    top: 25px;
    left: 50%;

    transform: translateX(-50%);

    width: 100px;

}

@media (max-width: 768px) {

    h1 {
        font-size: 80px;
    }

    h1 br {
        display: initial;
    }

}

@media (max-width: 600px) {

    h1 {
        font-size: 60px;
    }

    img {
        width: 80px;
    }

}

</style>
